.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  z-index: 3;
}

.company {
    color: #fff;
    margin: 40px;
    font-size: 1.5vmin;
    z-index: 2;
}

.App-main {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video {
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    opacity: 0.7;
    z-index: 1;
}

.bottom {
    position: fixed;
    bottom: 10px;
    z-index: 2;
}

.icp {
    width: 100%;
    height: 100px;
    line-height: 100px;
    position: fixed;
    text-align: center;
    font-size: 1vmin;
    color: #fff;
    bottom: 0px;
    text-shadow: 10px 10px 900px black;
}
